import { defineStore } from "pinia";
import type AlgoliaData from "~/types/AlgoliaData";

export const useSearchStore = defineStore("searchStore", {
    persist: false,
    state: () => ({
        searchText: "",

        pageMounted: false, // Flag to allow watchers to run
        loading: false,
        pageNum: "1",
        selectedState: "",
        selectedFilters: [],
        selectedSortBy: "",
        selectedPageLimit: "20",
        result: [] as AlgoliaData | any,
    }),
    getters: {},
    actions: {},
});
